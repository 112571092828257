import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import './index.css'

const TemplateWrapper = ({ children }) => (
  <Fragment>
    <Helmet>
      <title>Elis Glynne</title>
      <noscript>{`<h1>Hey I&apos;m Elis Glynne. And this is my retro looking site!</h1><p>I&apos; a Front-end developer who loves to design, prototype and develop user interfaces for web and mobile.</p>`}</noscript>
      <meta name="description" content="Front-end developer who loves to design, prototype and develop user interfaces for web and mobile." />
      <meta name="keywords" content="front, end, developer, react, redux, cardiff, wales, cymru, prototype, design" />
      <html lang="en-GB" />
    </Helmet>
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
        height: '100vh',
      }}
    >
      {children}
    </div>
  </Fragment>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
