import React from 'react';
import Link from 'gatsby-link';
import TemplateWrapper from '../layouts';
import '../layouts/dandyflex.css';
import '../layouts/style.css';

const IndexPage = () => (
  <TemplateWrapper>
    <div className="promo position center is-column">
        <h1 className="header">Elis Glynne</h1>
        <div className="underscore" />
        <p>Front-end developer who loves to design, prototype and develop user interfaces for web and mobile. <br />
      Currently working as an engineer for <a href="https://crowdcube.com" title="Website for crowdcube">Crowdcube</a>.</p>
    </div>
    <noscript>Hi there. I'm Elis. I'm a front-end engineer who loves to design, prototype and develop user interfaces.</noscript>
  </TemplateWrapper>
)

export default IndexPage
